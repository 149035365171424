import React from 'react'
import styled from 'styled-components'
import { Section } from "../../components/Section"
import PlusIcon from "../../components/Icons/PlusIcon"
import FilterIcon from "../../components/Icons/FilterIcon"
import { Button } from "../../components/FormStyle"
import ProductCardList from "../../components/ProductCardList"

const FilterContainer = styled.div`
	position: relative;
	background: white;
	max-width: 1350px;
	width: 100%;
	margin: auto;
	margin-top: -287px !important;
	@media(max-width: 768px) {
		margin-top: -168px !important;
	}
	@media(max-width: 480px) {
		margin-top: -50px !important;
	}
`

const FilterDrop = styled.div`
	border: 1px solid rgba(30, 65, 86, 0.25);
	box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	font-size: 30px;
	line-height: 36px;
	font-weight: 800;
	color: #062C44;
	display: ${props => props.display};
	padding: 38px 62px;
	background: whtie;
	margin-top: 17px;
	width: inherit;
	& .h3 {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 50px);
	}
	& svg {
		float: right;
		width: 50px;
		display: inline-block;
	}
	@media(max-width: 900px) {
		& svg {
			float: right;
			width: 50px;
			display: inline-block;
	    margin-top: 20px;
		}
	}
	@media(max-width: 768px) {
		padding: 30px 40px;
		font-size: 24px;
		line-height: 30px;
		& span {
			margin-right: 20px;
		}
		& svg {
	    margin-top: 15px;
		}
	}
	@media(max-width: 480px) {
		padding: 10px 20px;
		font-size: 20px;
		& svg {
	    margin-top: 30px;
		}
	}
`
const FilterWrap = styled.div`
	position: relative;
	border: 1px solid rgba(30, 65, 86, 0.25);
	box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	display: ${props => props.display};
	padding-top: 20px;
	& svg {
		transform: rotate(-45deg);
    &:hover {
    	pointer: cursor;
    }
	}
	width: 100%;
	@media(max-width: 480px) {
		margin-top: 17px;
	}
`
const FilterItemsContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(2, auto);
	& h4 {
		padding: 0px 60px 20px;
		border-bottom: 1px solid #C6CFD4;
	}
	@media(max-width: 1023px) {
		grid-template-columns: repeat(1, auto);
	}
	@media(max-width: 768px) {
		& h4 {
			padding: 0px 20px 20px;
		}
	}
`
const FilterBy = styled.div`
	position: relative;
	width: 100%;
`
const FilterItems = styled.div`
	padding: 40px 0px 40px 60px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: space-between;
	@media(max-width: 768px) {
		padding: 20px;
	}
	@media(max-width: 500px) {
		grid-template-columns: repeat(1, 1fr);
		& h5 {
			margin: 10px 0;
		}
	}
`
const FilterItem = styled.div`
	font-size: 16px;
	font-weight: bold;
	line-height: 26px;
	margin: 18px 0;
	color: #676b79;
	@media(max-width: 500px) {
		margin: 5px 0;
	}
`
const SortItems = styled.div`
	padding: 40px 60px;
	& h5 {
		font-size: 16px;
	}
	@media(max-width: 768px) {
		padding: 20px;
	}
`

const SortItem = styled.div`
	font-size: 16px;
	font-weight: bold;
	line-height: 26px;
	margin: 18px 0;
	color: #676b79;
`
const PriceRangeWapper = styled.div`
	width: 350px;
	@media(max-width: 768px) {
		width: 200px;
	}
	@media(max-width: 480px) {
		width: 100%;
	}
`
const PriceRange = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-width: 350px;
	margin-top: 10px;
	@media(max-width: 480px) {
		max-width: 100%;
	}
`
const PriceItem = styled.label`
	width: fit-content;
	background: ${props => props.bg};
	border: 1px solid ${props => props.borderColor};;
	padding: 11px 22px;
	border-radius: 40px;
	font-size: 16px;
	font-weight: bold;
	line-height: 26px;
	color: ${props => props.color};
	margin: 10px 10px 0px 0px;
	& input {
		display: none;
	}
	@media(max-width: 600px){
		padding: 5px 10px;
		margin: 5px 5px 0 0;
	} 
`
const ButtonWrap = styled.div`
	border-top: 1px solid #C6CFD4;
	padding: 30px 60px;
	@media(max-width: 768px) {
		padding: 20px;
	}
`
const ClearButton = styled.button`
	margin-left: 20px;
	padding: 20px;
	background: white;
	border: 0px;
	color: #ED602B;
	font-size: 20px;
	font-weight: 800;
	line-height: 26px;
	display: inline-block;
	vertical-align: top;
	@media(max-width: 480px) {
		margin-left: 0px;
	}
`
const CloseIcon = styled.div`
	width: 15px;
	height: 15px;
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	z-index: 1;
	@media(max-width: 480px) {
		width: 20px;
		height: 20px;
		top: 15px;
	}
`
class SearchSection extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isFilterable: false,
			filteringData: this.props.data,
			productData: this.props.data,
			carports: false,
			garages: false,
			barns: false,
			rvCovers: false,
			storageBuildings: false,
			commericalBuildings: false,
			utilityBuildings: false,
			verticalRoof: false,
			regularRoof: false,
			aframeRoof: false,
			priceFirst: true,
			priceSecond: true,
			priceThird: true,
			priceForth: true,
			priceFifth: true,
			priceSixth: true,
			sort: 'desc',
			sortValue: ""
		}
		this.applyFilter = this.applyFilter.bind(this)
		this.handleFilterItem = this.handleFilterItem.bind(this)
		this.handleSortItem = this.handleSortItem.bind(this)
		this.clearFilter = this.clearFilter.bind(this)
		this.productList = this.productList.bind(this)
	}
	filterShow() {
		let flag = !this.state.isFilterable
		this.setState({
			isFilterable: flag,
		})
	}
	handleFilterItem(event) {
		const { name, checked } = event.target
		this.setState({
			[name]: checked,
		})
	}
	handleSortItem(event) {
		const { name, value } = event.target
		this.setState({
			[name]: value,
			productData: this.state.productData.reverse()
		})
	}
	clearFilter() {
		this.setState({
			carports: false,
			garages: false,
			barns: false,
			rvCovers: false,
			storageBuildings: false,
			commericalBuildings: false,
			utilityBuildings: false,
			verticalRoof: false,
			regularRoof: false,
			aframeRoof: false,
			priceFirst: false,
			priceSecond: false,
			priceThird: false,
			priceForth: false,
			priceFifth: false,
			priceSixth: false,
			sort: 'desc',
			filteringData: this.props.data,
		})
		this.carportsInput.checked = false
		this.garagesInput.checked = false
		this.barnsInput.checked = false
		this.rvCoversInput.checked = false
		this.storageBuildingsInput.checked = false
		this.commericalBuildingsInput.checked = false
		this.utilityBuildingsInput.checked = false
		this.verticalRoofInput.checked = false
		this.regularRoofInput.checked = false
		this.aframeRoofInput.checked = false
		this.priceFirstInput.checked = false
		this.priceSecondInput.checked = false
		this.priceThirdInput.checked = false
		this.priceForthInput.checked = false
		this.priceFifthInput.checked = false
		this.priceSixthInput.checked = false
	}
	componentDidMount() {
		this.priceFirstInput.checked = true
		this.priceSecondInput.checked = true
		this.priceThirdInput.checked = true
		this.priceForthInput.checked = true
		this.priceFifthInput.checked = true
		this.priceSixthInput.checked = true
		if (this.state.sort === "asc") {
			this.sortDesc.checked = false
			this.sortAsc.checked = true
		} else {
			this.sortDesc.checked = true
			this.sortAsc.checked = false
		}
	}

	applyFilter() {
		const { productData } = this.state

		let tempFilterCategoryData = []
		let tempFilterRoofData = []
		let tempPriceData = []
		let flag = 0
		let roofFlag = 0
		let priceFlag = 0

		if (this.state.priceFirst) {
			productData.forEach(function (element, index) {
				if (element.node.price < 2000) {
					tempPriceData.push(element)
				}
			})
			priceFlag = priceFlag + 1
		}
		if (this.state.priceSecond) {
			productData.forEach(function (element, index) {
				if (element.node.price > 2000 && element.node.price < 5000) {
					tempPriceData.push(element)
				}
			})
			priceFlag = priceFlag + 1
		}
		if (this.state.priceThird) {
			productData.forEach(function (element, index) {
				if (element.node.price > 5000 && element.node.price < 10000) {
					tempPriceData.push(element)
				}
			})
			priceFlag = priceFlag + 1
		}
		if (this.state.priceForth) {
			productData.forEach(function (element, index) {
				if (element.node.price > 10000 && element.node.price < 20000) {
					tempPriceData.push(element)
				}
			})
			priceFlag = priceFlag + 1
		}
		if (this.state.priceFifth) {
			productData.forEach(function (element, index) {
				if (element.node.price > 20000 && element.node.price < 40000) {
					tempPriceData.push(element)
				}
			})
			priceFlag = priceFlag + 1
		}
		if (this.state.priceSixth) {
			productData.forEach(function (element, index) {
				if (element.node.price > 40000) {
					tempPriceData.push(element)
				}
			})
			priceFlag = priceFlag + 1
		}
		if (priceFlag === 0) {
			tempPriceData = productData
		}

		if (this.state.carports) {
			tempPriceData.forEach(function (element, index) {
				if (element.node.productCategory.name === "Carports") {
					tempFilterCategoryData.push(element)
				}
			});
			flag = flag + 1
		}
		if (this.state.garages) {
			tempPriceData.forEach(function (element, index) {
				if (element.node.productCategory.name === "Garages") {
					tempFilterCategoryData.push(element)
				}
			});
			flag = flag + 1
		}
		if (this.state.barns) {
			tempPriceData.forEach(function (element, index) {
				if (element.node.productCategory.name === "Barns") {
					tempFilterCategoryData.push(element)
				}
			});
			flag = flag + 1
		}
		if (this.state.rvCovers) {
			tempPriceData.forEach(function (element, index) {
				if (element.node.productCategory.name === "RV Covers") {
					tempFilterCategoryData.push(element)
				}
			});
			flag = flag + 1
		}
		if (this.state.utilityBuildings) {
			tempPriceData.forEach(function (element, index) {
				if (element.node.productCategory.name === "Utility Buildings") {
					tempFilterCategoryData.push(element)
				}
			});
			flag = flag + 1
		}
		if (this.state.commericalBuildings) {
			tempPriceData.forEach(function (element, index) {
				if (element.node.productCategory.name === "Commerical Buildings") {
					tempFilterCategoryData.push(element)
				}
			});
			flag = flag + 1
		}
		if (this.state.storageBuildings) {
			tempPriceData.forEach(function (element, index) {
				if (element.node.productCategory.name === "Storage Buildings") {
					tempFilterCategoryData.push(element)
				}
			});
			flag = flag + 1
		}

		if (flag === 0) {
			tempFilterCategoryData = tempPriceData
		}

		if (this.state.verticalRoof) {
			tempFilterCategoryData.forEach(function (element, index) {
				if (element.node.productRoofType.name === "Vertical Roof") {
					tempFilterRoofData.push(element)
				}
			});
			roofFlag = roofFlag + 1
		}
		if (this.state.aframeRoof) {
			tempFilterCategoryData.forEach(function (element, index) {
				if (element.node.productRoofType.name === "A-Frame Roof") {
					tempFilterRoofData.push(element)
				}
			});
			roofFlag = roofFlag + 1
		}
		if (this.state.regularRoof) {
			tempFilterCategoryData.forEach(function (element, index) {
				if (element.node.productRoofType.name === "Regular Roof") {
					tempFilterRoofData.push(element)
				}
			});
			roofFlag = roofFlag + 1
		}

		if (roofFlag === 0) {
			tempFilterRoofData = tempFilterCategoryData
		}

		if (this.state.sort === "asc") {
			tempFilterRoofData.sort((a, b) => (a.node.price > b.node.price) ? 1 : -1)
		} else {
			tempFilterRoofData.sort((a, b) => (a.node.price > b.node.price) ? -1 : 1)
		}

		this.setState({
			filteringData: tempFilterRoofData,
			isFilterable: !this.state.isFilterable
		});
	}

	productList(data, location) {
		if (data.length === 0) {
			return (
				<h2 style={{ textAlign: "center", marginTop: "100px" }}>
					Couldn't find any building with matching your search criteria
				</h2>
			)
		} else {
			return (
				<ProductCardList data={data} location={location} />
			)
		}
	}

	render() {
		const { isFilterable, filteringData, priceFirst, priceSecond, priceThird, priceForth, priceFifth, priceSixth } = this.state
		const { location } = this.props;
		return (
			<Section pt="217px" pb="87px" bg="#F4FBFF">
				<div className="container">
					<FilterContainer>
						<FilterDrop onClick={() => this.filterShow()} display={isFilterable ? "none" : "block"}>
							<div className="h3">Filter the Buildings with your specific needs and the building sizes</div>
							<FilterIcon />
						</FilterDrop>
						<FilterWrap display={isFilterable ? "block" : "none"}>
							<CloseIcon onClick={() => this.filterShow()}>
								<span>
									<PlusIcon fill="#1E4156" />
								</span>
							</CloseIcon>
							<FilterItemsContainer>
								<FilterBy>
									<h4>Filter By - </h4>
									<FilterItems>
										<div>
											<h5><label htmlFor="title">All Metal Buildings</label></h5>
											<FilterItem>
												<label><input type="checkbox" name="carports" onChange={this.handleFilterItem}
													ref={(input) => { this.carportsInput = input; }} /> Carports</label>
											</FilterItem>
											<FilterItem>
												<label><input type="checkbox" name="garages" onChange={this.handleFilterItem} ref={(input) => { this.garagesInput = input; }} /> Garages</label>
											</FilterItem>
											<FilterItem>
												<label><input type="checkbox" name="barns" onChange={this.handleFilterItem} ref={(input) => { this.barnsInput = input; }} /> Barns</label>
											</FilterItem>
											<FilterItem>
												<label><input type="checkbox" name="rvCovers" onChange={this.handleFilterItem} ref={(input) => { this.rvCoversInput = input; }} /> RV Covers</label>
											</FilterItem>
											<FilterItem>
												<label><input type="checkbox" name="utilityBuildings" onChange={this.handleFilterItem} ref={(input) => { this.utilityBuildingsInput = input; }} /> Utility Buildings</label>
											</FilterItem>
											<FilterItem>
												<label><input type="checkbox" name="commericalBuildings" onChange={this.handleFilterItem} ref={(input) => { this.commericalBuildingsInput = input; }} /> Commerical Buildings</label>
											</FilterItem>
											<FilterItem>
												<label><input type="checkbox" name="storageBuildings" onChange={this.handleFilterItem} ref={(input) => { this.storageBuildingsInput = input; }} /> Storage Buildings</label>
											</FilterItem>
										</div>
										<div>
											<h5><label htmlFor="title">All Roof Style</label></h5>
											<FilterItem>
												<label><input type="checkbox" name="verticalRoof" onChange={this.handleFilterItem} ref={(input) => { this.verticalRoofInput = input; }} /> Vertical Roof</label>
											</FilterItem>
											<FilterItem>
												<label><input type="checkbox" name="aframeRoof" onChange={this.handleFilterItem} ref={(input) => { this.aframeRoofInput = input; }} /> A-Frame Roof</label>
											</FilterItem>
											<FilterItem>
												<label><input type="checkbox" name="regularRoof" onChange={this.handleFilterItem} ref={(input) => { this.regularRoofInput = input; }} /> Regular Roof</label>
											</FilterItem>
										</div>
										<PriceRangeWapper>
											<h5>Price Range</h5>
											<PriceRange>
												<PriceItem bg={priceFirst ? "#EA5F2C" : "none"} borderColor={priceFirst ? "#EA5F2C" : "rgba(30, 65, 86, 0.25)"} color={priceFirst ? "white" : "#676b79"}>
													<input type="checkbox" name="priceFirst" onChange={this.handleFilterItem} ref={(input) => { this.priceFirstInput = input; }} />
													Under $2000
												</PriceItem>
												<PriceItem bg={priceSecond ? "#EA5F2C" : "none"} borderColor={priceSecond ? "#EA5F2C" : "rgba(30, 65, 86, 0.25)"} color={priceSecond ? "white" : "#676b79"}>
													<input type="checkbox" name="priceSecond" onChange={this.handleFilterItem} ref={(input) => { this.priceSecondInput = input; }} />
													$2000 - $5000
												</PriceItem>
												<PriceItem bg={priceThird ? "#EA5F2C" : "none"} borderColor={priceThird ? "#EA5F2C" : "rgba(30, 65, 86, 0.25)"} color={priceThird ? "white" : "#676b79"}>
													<input type="checkbox" name="priceThird" onChange={this.handleFilterItem} ref={(input) => { this.priceThirdInput = input; }} />
													$5000 - $10000
												</PriceItem>
												<PriceItem bg={priceForth ? "#EA5F2C" : "none"} borderColor={priceForth ? "#EA5F2C" : "rgba(30, 65, 86, 0.25)"} color={priceForth ? "white" : "#676b79"}>
													<input type="checkbox" name="priceForth" onChange={this.handleFilterItem} ref={(input) => { this.priceForthInput = input; }} />
													$10000 - $20000
												</PriceItem>
												<PriceItem bg={priceFifth ? "#EA5F2C" : "none"} borderColor={priceFifth ? "#EA5F2C" : "rgba(30, 65, 86, 0.25)"} color={priceFifth ? "white" : "#676b79"} >
													<input type="checkbox" name="priceFifth" onChange={this.handleFilterItem} ref={(input) => { this.priceFifthInput = input; }} />
													$20000 - $40000
												</PriceItem>
												<PriceItem bg={priceSixth ? "#EA5F2C" : "none"} borderColor={priceSixth ? "#EA5F2C" : "rgba(30, 65, 86, 0.25)"} color={priceSixth ? "white" : "#676b79"}>
													<input type="checkbox" name="priceSixth" onChange={this.handleFilterItem} ref={(input) => { this.priceSixthInput = input; }} />
													$40000 & Above
												</PriceItem>
											</PriceRange>
										</PriceRangeWapper>
									</FilterItems>
								</FilterBy>
								<div>
									<h4> Sort By -</h4>
									<SortItems>
										<h5>Sorting Options</h5>
										<SortItem>
											<label><input type="radio" name="sort" value="desc" onChange={this.handleSortItem} ref={(input) => { this.sortDesc = input; }} /> High to Low Price</label>
										</SortItem>
										<SortItem>
											<label><input type="radio" name="sort" value="asc" onChange={this.handleSortItem} ref={(input) => { this.sortAsc = input; }} /> Low to High Price</label>
										</SortItem>
									</SortItems>
								</div>
							</FilterItemsContainer>
							<ButtonWrap>
								<Button display="inline-block" width="190px" mWidth="111px" fSize="20px" onClick={this.applyFilter}><div>Apply</div></Button>
								<ClearButton onClick={this.clearFilter}>Clear All Filters</ClearButton>
							</ButtonWrap>
						</FilterWrap>
					</FilterContainer>
				</div>
				<div className="container">
					{this.productList(filteringData, location)}
				</div>
			</Section>
		)

	}
}

export default SearchSection