import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { createClient } from 'contentful-management';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, HeroTitle } from "../components/Section"
import axios from "axios";

import CallToAction from "../components/CallToAction"

import SearchSection from "../sections/Product/SearchSection"
import PrimaryButton from "../components/Button/PrimaryButton"
import e from "cors";


const SyncButton = styled.div`
	/* display:none; */
	text-align: center;
	width: fit-content;
	margin: 2rem auto;

	&:hover {
		cursor: pointer;
	}
`;

const ProductPage = (pageData) => {

	const [seseiProductsData, setSenseiProductsData] = useState([]);
	const productData = pageData.data.allContentfulProduct.edges;

	const getSeseiProducts = async () => {
		//console.log("get porducts")
		const res = await axios({
			method: "post",
			url: "https://crm.senseicrm.com/api/v1/product/listing",
			data: {
				limit: 1000,
			},
			headers: {
				Authorization:
					"Bearer " +
					"13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
			},
		})
		setSenseiProductsData(res.data.data);
	}

	useEffect(() => {
		getSeseiProducts()
	}, [])

	const syncProducts = () => {
		const senseiData = seseiProductsData.data
		const client = createClient({
			accessToken: 'CFPAT-6mLLDRiqRmKYIHJSVBKjT3J-TLCtbXdJE9NaykR0cWA'
		})
		const space = client.getSpace("z75g1qwq4el7");
		senseiData.forEach(element => {
			const senseiWebSku = element.web_sku.slice(3);
			const senseiId = element.id;
			const sameProduct = productData.find((item) => String(item.node.skuNumber) === senseiWebSku);
			const sameNameProduct = productData.find((item) => item.node.title === element.name) || "";
			let realImage = element.image_data.find((item) => item.is_default === 1) || null;
			if(!realImage)
			    realImage = element.image_data[element.image_data.length - 1];
			
			if (sameProduct !== undefined) {
				space
					.then((space) => space.getEnvironment('master'))
					.then((environment) => environment.getEntry(sameProduct.node.contentful_id))
					.then((entry) => {
						console.log(entry)
						entry.fields.senseiProductId['en-US'] = String(senseiId);
						entry.fields.imageSrc['en-US'] = realImage.image_url;
						return entry.update()
					})
					.then((entry) => console.log(`Entry ${entry.sys.id} updated.`))
					.catch(console.error)
			} else {
				if (sameNameProduct === "") {
					console.log("create new products")
					space.then((space) => space.getEnvironment('master'))
						.then((environment) => environment.createEntry('product', {
							fields: {
								title: {
									'en-US': element.name
								},
								skuNumber: {
									'en-US': Number(senseiWebSku)
								},
								senseiProductId: {
									'en-US': String(senseiId)
								},
								imageSrc: {
									'en-US': realImage.image_url
								},
								width: {
									'en-US': String(element.width)
								},
								height: {
									'en-US': String(element.height)
								},
								length: {
									'en-US': String(element.length)
								},
								price: {
									'en-US': String(element.price)
								}
							}
						}))
						//console.log(fields, "test")
						.then((entry) => console.log("created", entry))
						.catch(console.error)
				}
				
			}
		});
	}

	return (
		<Layout location={pageData.location}>
			<SEO title="Products" description="Products List Page" />
			<Section pt="207px" pb="100px" mtp="150px">
				<HeroTitle>
					Explore All Building Products
				</HeroTitle>
				<p>
					ProBuilt’s primary mission is to help our customers protect their automobiles, farm equipment, gardening accessories, and so much more by providing the highest quality metal buildings. ProBuilt Steel Buildings are designed to be highly functional, versatile, and meet superior engineering standards. They are the perfect addition to any home or business.
				</p>
				<SyncButton onClick={() => syncProducts()}>
					<PrimaryButton text="Explore All Products" />
				</SyncButton>
			</Section>
			<SearchSection data={productData} location={pageData.location} />
			<CallToAction />
		</Layout>
	)
}

export default ProductPage

export const pageQuery = graphql`
  query ProductPageQuery {
    allContentfulProduct(sort: {fields: skuNumber}) {
	    edges {
	      node {
	      	id
					contentful_id
					skuNumber
					imageSrc
	        image {
	          fluid(maxWidth: 700, quality: 100) {
	            base64
	            sizes
	            aspectRatio
	            src
	            srcSet
	            srcSetWebp
	            srcWebp
	          }
	        }
	        title
	        width
	        length
	        height
	        price
	        priceDescription
	        productServices {
	          spaceId
	          service
	          image {
	            fluid(maxWidth: 45, quality: 100) {
	              aspectRatio
	              base64
	              sizes
	              src
	              srcWebp
	              srcSetWebp
	              srcSet
	            }
	          }
	        }
	        productRoofType {
	          name
	        }
	        productCategory {
	          name
	        }
	        numberOfSale
	        canBeUsedAs {
	          content
	        }
	      }
	    }
	  }
	}
`