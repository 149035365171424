import React from 'react'

const FilterIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="30.792" height="30" viewBox="0 0 30.792 30">
	  <g id="levels" transform="translate(0 -6.3)">
	    <g id="Group_2246" data-name="Group 2246" transform="translate(0 6.3)">
	      <g id="Group_2245" data-name="Group 2245" transform="translate(0 0)">
	        <path id="Path_15254" data-name="Path 15254" d="M1.081,178.13H14.843a4.554,4.554,0,0,0,8.848,0h6.02a1.078,1.078,0,1,0,0-2.155h-6.02a4.554,4.554,0,0,0-8.848,0H1.081a1.078,1.078,0,1,0,0,2.155Zm18.186-3.469a2.394,2.394,0,1,1-2.4,2.394A2.4,2.4,0,0,1,19.267,174.662Z" transform="translate(0 -162.056)" fill="#ed602b"/>
	        <path id="Path_15255" data-name="Path 15255" d="M1.081,344.43h6.6a4.554,4.554,0,0,0,8.848,0H29.711a1.078,1.078,0,1,0,0-2.155H16.533a4.554,4.554,0,0,0-8.848,0h-6.6a1.078,1.078,0,1,0,0,2.155Zm11.028-3.475A2.394,2.394,0,1,1,9.715,343.4v-.113A2.4,2.4,0,0,1,12.109,340.955Z" transform="translate(0 -317.906)" fill="#ed602b"/>
	        <path id="Path_15256" data-name="Path 15256" d="M1.081,11.93h6.6a4.554,4.554,0,0,0,8.848,0H29.711a1.078,1.078,0,1,0,0-2.155H16.533a4.554,4.554,0,0,0-8.848,0h-6.6a1.078,1.078,0,1,0,0,2.155ZM12.109,8.455A2.394,2.394,0,1,1,9.715,10.9v-.113A2.4,2.4,0,0,1,12.109,8.455Z" transform="translate(0 -6.3)" fill="#ed602b"/>
	      </g>
	    </g>
	  </g>
	</svg>
)

export default FilterIcon
